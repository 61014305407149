@charset "UTF-8";

.swiper-wrapper{
    align-items: center;
}
.swiper-pagination{
    margin-top: 20px;
    position: static;
}
.swiper-pagination-bullet{
    background: #999999;
    height: 20px;
    opacity: 1;
    width: 20px;
}

.swiper-pagination-bullet-active{
    background: #000000;
}

.swiper-button-ctrl{
    color: #000000;
}

@media screen and (max-width: 900px) {
    .swiper-button-ctrl::after{
        font-size: 25px;
    }
}